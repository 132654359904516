import React from 'react'
import { useMediaQuery } from '@mui/material'
import MainWrapper from '../components/common/MainWrapper'

export default function PrivacyPolicy() {
  const isMedia1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <MainWrapper>
      <div className={`w-full flex justify-center h-full`}>
        <div
          className={`rounded-lg ${
            isMedia1025px ? 'w-full' : 'w-[50%]'
          }   color-border-default border shadow-lg overflow-auto styled-scrollbar p-8`}
        >
          <div className="font-bold mb-4 text-lg">FILEVERSE PRIVACY POLICY</div>
          <div className="mb-6 text-gray-600">
            Fileverse PTE. LTD. (which operates the website Fileverse.io and
            certain services under the name “Fileverse Portal” and “Fileverse
            Solo” is referred to as “Fileverse” in this Privacy Policy),
            provides a privacy-preserving and customizable collaboration
            platform. This Privacy Policy is designed to help you understand how
            we collect, use, and share your information, and to help you
            understand and exercise your privacy choices
            <br />
            <br />
          </div>
          <div className="mb-6 text-gray-600">
            <ul className="list-disc">
              <li className="ml-8">
                <b>Scope</b> {' : '}
                This Privacy Policy applies to information we collect from
                visitors and users of our websites and services.
              </li>
              <li className="ml-8">
                <b>Personal Information We Collect</b>
                {' : '}
                Fileverse is committed to safeguarding the privacy of the users
                on our platform. While you are on our platform, we collect only
                a minimal amount of data needed to provide you with our
                services. However, we may collect additional types of
                information when you visit our website or communicate with us
                via other channels, such as email. We explain the types
                information we collect in each of these mediums below.
                <ol type="A">
                  <li>
                    <b> Information You Provide to Us Directly</b>
                    {' : '}
                    We may collect the following information that you provide to
                    us.
                    <ul>
                      <li>
                        <b>Digital Wallet information.</b>
                        {' : '}
                        “Digital Wallet” means an application or software (e.g.
                        any Ethereum wallet, such as MetaMask or Rainbow Wallet)
                        that customers may use to secure their private keys and
                        execute blockchain transactions. If you connect your
                        Digital Wallet in order to access our Services, we may
                        collect your Wallet public key address.
                      </li>
                      <li>
                        <b>Interactive Features.</b>
                        {' : '}
                        We collect information you choose to share with us
                        through our interactive features (e.g., content sharing,
                        messaging and chat features, etc.). Any information you
                        provide to us using the sharing features of the Services
                        ( “User Content”) will be accessible to us and other
                        users you share the User Content with.
                      </li>
                      <li>
                        <b>Your Communications with Us. </b>
                        {' : '}
                        We may collect information such as your email address,
                        when you request information about our Services, request
                        customer or technical support, apply for a job, or
                        otherwise communicate with us.
                      </li>
                      <li>
                        <b> Job Applications</b>
                        {' : '}
                        We may post job openings and opportunities on our
                        Services. If you reply to one of these postings by
                        submitting your application, CV and/or cover letter to
                        us, we will collect and use your information to assess
                        your qualifications.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Information Collected Automatically </b>
                    We may collect certain information automatically when you
                    visit our website or use the services to provide and measure
                    our services and for security and fraud prevention purposes.
                    However, you are free to use our service via the static
                    website stored on IPFS and IPNS, which does not involve any
                    servers of ours.
                    <ul className="list-disc">
                      <li>
                        <b>Automatic Data Collection.</b>
                        In order to protect you and our platform from malicious
                        activity and to prevent fraudulent activity, we may
                        collect certain information automatically when you use
                        our Services, such as your Internet protocol (IP)
                        address (temporarily), user settings, and cookies.
                      </li>
                      <li>
                        <b>Cookies and Other Technologies.</b>
                        Cookies are small text files placed in device browsers
                        that store preferences and facilitate and enhance your
                        experience. We may use cookies to automatically collect
                        information through your use of our Services.
                      </li>
                      <br />
                      <br />
                      <>
                        Our uses of these Technologies fall into the following
                        general categories:
                      </>
                      <li>
                        <b>Operationally Necessary.</b>
                        We may use Technologies to assess the performance of our
                        Services, including as part of our analytic practices to
                        help us understand how individuals use our Services (see
                        Analytics below);
                      </li>
                      <li>
                        <b>Functionality-Related.</b>
                        We may use Technologies that allow us to offer you
                        enhanced functionality when accessing or using our
                        Services. This may include identifying you when you sign
                        into our Services or keeping track of your specified
                        preferences;
                      </li>
                      <li>
                        <b>Analytics</b>
                        We may use Technologies and other third-party tools,
                        including those provided by Google Analytics. Hotjar and
                        Sentry, to better understand how individuals use our
                        platform. These third-party tools are exclusively used
                        in Fileverse Solo (beta.fileverse.io).
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <b>How We Use Your Information</b> {' : '}
                Information collected through the platform: As noted above, we
                use the limited information collected from you while you are on
                our platform only to provide you with our services.{' '}
                <b>
                  We do not have access to the content you upload to Fileverse,
                  and therefore do not make any use of the information contained
                  in that content.
                </b>
                <br />
                <br />
                Other information: We use the information we collect from you
                through our website and other off-platform channels primarily to
                provide our Services, for security and fraud prevention, and for
                administrative purposes, and to market our products and
                Services, as described below.
                <ol type="A">
                  <li>
                    <b> Administering Our Services</b>
                    <br />
                    We use information collected off-platform for administrative
                    purposes, such as:
                    <ul>
                      <li>Administering your account</li>
                      <li>
                        {' '}
                        Providing access to certain areas, functionalities, and
                        features of our website;
                      </li>

                      <li>
                        Answering requests for customer or technical support
                      </li>

                      <li>
                        {' '}
                        Communicating with you about your account, activities on
                        our Services, and policy changes;
                      </li>

                      <li>
                        {' '}
                        Processing your financial information and other payment
                        methods for products or Services purchased
                      </li>
                      <li>
                        Processing applications if you apply for a job we post
                        on our Services
                      </li>
                      <li>Allowing you to register for events.</li>
                    </ul>
                  </li>
                  <li>
                    <b>Operational Purposes</b>
                    <br />
                    We use your information to operate our business, such as:
                    <ul>
                      <li>
                        Preventing and detecting security incidents, protecting
                        against malicious, deceptive, fraudulent or illegal
                        activity, and prosecuting those responsible for that
                        activity;
                      </li>
                      <li> Improving, upgrading or enhancing our Services;</li>
                      <li>Developing new products and Services;</li>
                      <li>Ensuring internal quality control and safety;</li>
                      <li>
                        Authenticating and verifying individual identities,
                        including requests to exercise your rights under this
                        policy;
                      </li>
                      <li>
                        Debugging to identify and repair errors with our
                        Services;
                      </li>
                      <li> Enforcing our agreements and policies; and</li>
                      <li>Complying with our legal obligations.</li>
                    </ul>
                  </li>
                  <li>
                    <b>Marketing and Advertising our Products and Services</b>
                    <br />
                    We may use information to market and advertise our products
                    to you directly if you have provided us with your email
                    address. This includes marketing via email campaigns and
                    notifications within the Platform. You can opt out of direct
                    email marketing messages from us by clicking the
                    “unsubscribe” button included in the footer of the emails we
                    send you.
                  </li>
                  <li>
                    <b>Disclosures to Provide our Services</b>
                    <br />
                    The content you upload to Fileverse is stored in end-to-end
                    encrypted form on IPFS. We do not share user content with
                    any other third party.
                  </li>
                  <li>
                    <b>Disclosures to Protect Us or Others</b>
                    <br />
                    We may access, preserve, and disclose your information if
                    we, in good faith, believe doing so is required or
                    appropriate to: comply with law enforcement or national
                    security requests and legal process, such as a court order
                    or subpoena; protect your, our, or others’ rights, property,
                    or safety; enforce our policies or contracts; collect
                    amounts owed to us; or assist with an investigation or
                    prosecution of suspected or actual illegal activity. Please
                    note, however, that we do not have the ability to decrypt
                    any content uploaded to our platform.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
          <div className="font-bold mb-4 text-base">
            Security of Your Information
          </div>
          <div className="mb-6 text-gray-600">
            We take steps to ensure that your information is treated securely
            and in accordance with this Privacy Policy. Unfortunately, no system
            is 100% secure, and we cannot ensure or warrant the security of any
            information you provide to us. We have taken appropriate safeguards
            to require that your personal information will remain protected and
            require our third-party service providers and partners to have
            appropriate safeguards as well.
            <br />
            By using our Services or providing information to us, you agree that
            we may communicate with you electronically regarding security,
            privacy, and administrative issues relating to your use of our
            Services. If we are required to notify you of a security incident,
            we may attempt to notify you electronically by posting a notice on
            our Services, by mail or by sending an email to you.
          </div>
          <div className="font-bold mb-4 text-base">
            International Data Transfers
          </div>
          <div className="mb-6 text-gray-600">
            All information processed by us may be transferred, processed, and
            stored anywhere in the world, including, but not limited to, the
            United Kingdom or other countries, which may have data protection
            laws that are different from the laws where you live. We endeavor to
            safeguard your information consistent with the requirements of
            applicable laws.
          </div>
          <div className="font-bold mb-4 text-base">
            Retention of Personal Information
          </div>
          <div className="mb-6 text-gray-600">
            We store the personal information we collect as described in this
            Privacy Policy for as long as you use our Services or as necessary
            to fulfill the purpose(s) for which it was collected, provide our
            Services, resolve disputes, establish legal defenses, conduct
            audits, pursue legitimate business purposes, enforce our agreements,
            and comply with applicable laws.
          </div>
          <div className="font-bold mb-4 text-base">
            Children&apos;s Information
          </div>
          <div className="mb-6 text-gray-600">
            The Services are not directed to children under 18 , and we do not
            knowingly collect personal information from children.
            <br />
            If you are a parent or guardian and believe your child has uploaded
            personal information to our site without your consent, you may
            contact us as described in “Contact Us” below. If we become aware
            that a child has provided us with personal information in violation
            of applicable law, we will delete any personal information we have
            collected, unless we have a legal obligation to keep it, and
            terminate the child’s account if applicable.
          </div>
          <div className="font-bold mb-4 text-base">
            Changes to our Privacy Policy.
          </div>
          <div className="mb-6 text-gray-600">
            The Services are not directed to children under 18 , and we do not
            knowingly collect personal information from children.
          </div>
          <div className="mb-6 text-gray-600">
            We may revise this Privacy Policy from time to time in our sole
            discretion. If there are any material changes to this Privacy
            Policy, we will notify you as required by applicable law. You
            understand and agree that you will be deemed to have accepted the
            updated Privacy Policy if you continue to use our Services after the
            new Privacy Policy takes effect.
          </div>
          <div className="font-bold mb-4 text-lg">CONTACT US</div>
          <div className="mb-6 text-gray-600">
            If you have any questions about our privacy practices or this
            Privacy Policy, or to make requests about your information as
            detailed in this Privacy Policy, please contact us at: relevant
            behavior or content to{' '}
            <a
              href="mailto:hello@fileverse.io"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              hello@fileverse.io
            </a>
          </div>
        </div>
      </div>
    </MainWrapper>
  )
}
