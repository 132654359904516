import React from 'react'
import { useMediaQuery } from '@mui/material'
import MainWrapper from '../components/common/MainWrapper'

export default function TermsCondition() {
  const isMedia1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <MainWrapper>
      <div className={`w-full flex justify-center h-full`}>
        <div
          className={`rounded-lg ${
            isMedia1025px ? 'w-full' : 'w-[50%]'
          }   color-border-default border shadow-lg overflow-auto styled-scrollbar p-8`}
        >
          <div className="font-bold mb-4 text-xl">Terms of service</div>
          <div className="mb-6 text-gray-600">
            The following terms and conditions govern all use of the Fileverse
            website (“Fileverse” or the “Website”), and all content, services
            and products available at or through the Website. The Services are
            offered subject to your acceptance without modification of all of
            the terms and conditions contained herein. As all data uploaded to
            Fileverse will be available via IPFS, the Services are also subject
            to the{' '}
            <a
              href="https://discuss.ipfs.io/tos"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              Terms of Service of IPFS.io
            </a>{' '}
            and{' '}
            <a
              href="https://discuss.ipfs.io/privacy"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              Privacy Policy of IPFS.io
            </a>{' '}
            and{' '}
            <a
              href="https://web3.storage/terms/"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              Terms of Service of Web3.storage
            </a>{' '}
            which is the service used for file pinning.
            <br />
            <br />
            If you do not agree to all the terms and conditions of this
            agreement (“Agreement” or “TOS”), then you may not access the
            Website or use any of the Services.
            <br />
            <br />
            Fileverse PTE. LTD. (“we,” “us”) may make changes to this Agreement
            and pricing from time to time. If you do not agree to these
            revisions, you may stop using the Services.
            <br />
            <br />
          </div>
          <a
            href="https://web3.storage/terms/#storage-term"
            target="_blank"
            rel="noreferrer"
            className="font-bold mb-4 text-lg"
          >
            Storage term
          </a>{' '}
          <div className="mb-6 text-gray-600">
            <br />
            Data stored via the Services will be available via IPFS and stored
            on Filecoin until the expiry of the respective deals (see “Filecoin
            deals” below for details). Fileverse uses web3.storage for all file
            pinning services.
            <br />
            We reserve the right to terminate the Services at our sole
            discretion or to transfer operation of the Services to a third party
            or smart contract. Prior to termination or transfer, we will provide
            90 days’ notice to users via email to allow users enough time to
            make arrangements if any (e.g. remote pinning to their own IPFS
            node, remote pinning to another service, or preparing to manage the
            renewal of their Filecoin deals on their own).
            <br />
            <br />
            In rare situations where exceptionally high read/write volume and/or
            excessive number of uploaded blocks has an adverse impact on other
            users’ service levels, we reserve the right to block access to those
            CIDs and/or block uploads without notice. To the extent possible, we
            will attempt to give notice to users whose uploaded CIDs are
            negatively impacting service levels before blocking access to CIDs.
            <br />
            <br />
            All data uploaded to Fileverse is available to anyone who requests
            it using the correct CID.
            <b>
              {' '}
              Users should not store any private or sensitive information in an
              unencrypted form using Fileverse.
            </b>
            Further, deleting files from Fileverse via the website will remove
            them from the file listing for a user&aposs account, but nodes on
            the IPFS network may retain copies of the data indefinitely. While
            users can encrypt data before uploading it to IPFS via Fileverse,
            thus rendering the data private,{' '}
            <b>
              they should not use Fileverse to store data that may need to be
              permanently deleted in the future.
            </b>
          </div>
          <a
            href="https://web3.storage/terms/#filecoin-deals"
            target="_blank"
            rel="noreferrer"
            className="font-bold mb-4 text-lg"
          >
            Filecoin deals
          </a>{' '}
          <div className="mb-6 text-gray-600">
            <br />
            Data stored via Fileverse will be available and persisted via IPFS
            upon completion of a successful upload as per web3.storage’s Terms
            and Conditions. Data stored via web3.storage is also pushed to
            storage on Filecoin, where the data is batched into deals. There may
            be a delay between content being uploaded via the Services and being
            stored on Filecoin through a deal.
            <br />
            <br />
            Fileverse uses web3.storage to manage backup storage deals and deal
            renewals to ensure highly redundant storage on the Filecoin network.
          </div>
        </div>
      </div>
    </MainWrapper>
  )
}
