import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import { Document, Page, pdfjs } from 'react-pdf'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'
import { ZoomInIcon, Minus, Plus, ZoomOutIcon } from 'lucide-react'
import debounce from 'lodash/debounce'
import cn from 'classnames'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const PdfViewer = ({ url }: { url: string }) => {
  const [numPages, setNumPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [inputValue, setInputValue] = useState('1')
  const [zoom, setZoom] = useState(1)
  const [pagesRendered, setPagesRendered] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [defaultZoom, setDefaultZoom] = useState(1)
  const isMobile = window.innerWidth < 768
  const debouncedSetZoom = useMemo(
    () =>
      debounce((zoomLevel) => {
        setZoom(zoomLevel)
      }, 100),
    []
  )

  const zoomIn = useCallback(() => {
    if (zoom < 1.5) {
      debouncedSetZoom(zoom + 0.1)
    }
  }, [zoom, debouncedSetZoom])

  const zoomOut = useCallback(() => {
    if (zoom > 0.5) {
      debouncedSetZoom(zoom - 0.1)
    }
  }, [zoom, debouncedSetZoom])

  const fitToWidth = useCallback(() => {
    debouncedSetZoom(defaultZoom || 1)
  }, [debouncedSetZoom, defaultZoom])

  const debouncedHandlePageChange = useMemo(
    () =>
      debounce((page: number) => {
        if (page >= 1 && page <= numPages) {
          setActivePage(page)
          const pageElement = document.querySelector(
            `.react-pdf__Page[data-page-number="${page}"]`
          )
          if (pageElement) {
            pageElement.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }, 500),
    [numPages]
  )

  const handlePageInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setInputValue(value)
      const page = parseInt(value, 10)
      if (!isNaN(page)) {
        debouncedHandlePageChange(page)
      }
    },
    [debouncedHandlePageChange]
  )

  useEffect(() => {
    setInputValue(activePage.toString())
  }, [activePage])

  const onDocumentLoadSuccess = useCallback(async (pdf: PDFDocumentProxy) => {
    const { numPages } = pdf
    setNumPages(numPages)
    setPagesRendered(Math.min(5, numPages)) // Initially render up to 5 pages
    if (!isMobile) return
    try {
      const pageOne = await pdf.getPage(1)
      const pageWidth = pageOne.view[2]
      const pageHeight = pageOne.view[3]
      const calculatedZoom = (pageWidth / pageHeight).toFixed(1)
      setZoom(parseFloat(calculatedZoom))
      setDefaultZoom(parseFloat(calculatedZoom))
    } catch (err) {
      console.error(err)
    }
  }, [])

  const onRenderSuccess = useCallback(() => {
    setPagesRendered((prev) => Math.min(prev + 1, numPages))
  }, [numPages])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    }

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const pageNumber = parseInt(
            entry.target.getAttribute('data-page-number') || '1',
            10
          )
          setActivePage(pageNumber)
        }
      })
    }

    observerRef.current = new IntersectionObserver(callback, options)

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      const pageElements = container.querySelectorAll('.react-pdf__Page')
      pageElements.forEach((el) => {
        if (observerRef.current) {
          observerRef.current.observe(el)
        }
      })
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [pagesRendered])

  const tooltipTitle = zoom >= 1.5 ? 'Reset zoom' : 'Fit to width'
  const Icon = zoom >= 1.5 ? ZoomOutIcon : ZoomInIcon

  return (
    <div
      data-cy="pdf-preview"
      className="h-full w-full flex flex-col relative justify-center items-center"
    >
      <div className="h-12 w-fit flex gap-4 justify-center items-center bg-black/70 rounded-full scale-[0.7] lg:scale-90 px-4 my-2 fixed bottom-12 lg:bottom-4 z-10">
        <div className="text-white w-fit">
          Page{' '}
          <input
            value={inputValue}
            onChange={handlePageInputChange}
            className="bg-[#191B1C] text-center w-[35px]"
          />{' '}
          / {numPages}
        </div>
        <div className="text-white flex gap-4 items-center">
          <Tooltip title="Zoom out">
            <button
              onClick={zoomOut}
              disabled={zoom <= 0.5}
              className={cn({
                'text-gray-400': zoom <= 0.5,
              })}
            >
              <Minus fontSize={16} />
            </button>
          </Tooltip>

          <span>
            <Tooltip title={tooltipTitle}>
              <button onClick={fitToWidth}>
                <Icon fontSize={16} />
              </button>
            </Tooltip>
          </span>
          <Tooltip title="Zoom in">
            <button
              onClick={zoomIn}
              disabled={zoom >= 1.5}
              className={cn({
                'text-gray-400': zoom >= 1.5,
              })}
            >
              <Plus fontSize={16} />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="w-full flex-grow overflow-auto flex-col h-full justify-center items-center relative">
        <div
          ref={containerRef}
          className="max-w-full flex flex-col items-center h-full"
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div className="text-white border flex justify-center">
                Loading PDF ...
              </div>
            }
          >
            {Array.from(new Array(pagesRendered), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={zoom}
                onRenderSuccess={
                  index + 1 === pagesRendered ? onRenderSuccess : undefined
                }
                loading={''}
                className="mb-4"
              />
            ))}
          </Document>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PdfViewer)
